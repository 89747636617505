import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function supplyChainNetworkReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccountTerm: true, term: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingAccountTerm: false, term: action.data };
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccountTerm: false, term: null };

        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingProducts: true, products: [], count: 0 };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingProducts: false, products: action.data?.data || [], count: action.data?.count };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingProducts: false, products: [], count: 0 };

        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isModifyingSubscription: true };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isModifyingSubscription: false };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isModifyingSubscription: false };
    

        default:
            return state;
    }
}


export default supplyChainNetworkReducer;
