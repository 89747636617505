import { Button, Modal, Notification, STATUS_TYPES } from "dyl-components";
import { Checkbox, Form, Grid } from "semantic-ui-react";
import SubscriptionsTable from "./SubscriptionsTable";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useState } from "react";
import subscriptionActions from "actions/subscription";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const RowCheckboxComponent = ({ id, disabled }) => {
    const [selectedItems, setSelectedItems] = useContext(BulkActionsContext);
    const isSelected = (id) => {
        return selectedItems.includes(id);
    };

    const onToggleItem = (subscription_id) => {
        const newSelectedItems = isSelected(subscription_id)
            ? selectedItems.filter((id) => subscription_id !== id)
            : [...selectedItems, subscription_id];
        setSelectedItems(newSelectedItems);
    };

    return (
        <Checkbox
            disabled={disabled}
            checked={isSelected(id)}
            onChange={() => {
                onToggleItem(id);
            }}
        />
    );
};

const EditSubscriptions = ({ open, onClose, account_id }) => {
    const { subscriptions, isModifyingSubscription } = useSelector((state) => ({
        subscriptions: state.subscription.products,
        isModifyingSubscription: state.subscription.isModifyingSubscription
    }));

    const [selectedItems,setSelectedItems] = useContext(BulkActionsContext);
    const itemsThatCanBeRemoved = subscriptions.filter(subscription => !subscription.user);
    const isNotAllowedToRemove = selectedItems.length <= 0 || itemsThatCanBeRemoved.length === selectedItems.length;
    const [isConfirming, setIsConfirming] = useState(false);

    const dispatch = useDispatch();

    const onModifySubscriptions = async () => {
        try {
            setIsConfirming(false);
            await dispatch(subscriptionActions.modifySubscription(selectedItems, null, account_id));
            Notification.alert("Successfully cancelled subscription(s)", STATUS_TYPES.SUCCESS);
            dispatch(subscriptionActions.getAllProducts(account_id));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to cancel subscription(s)", STATUS_TYPES.ERROR);
        }
    }

    return (
        <Modal open={open} onClose={() => {
            onClose();
            setSelectedItems([]);
        }}>
            <Modal.Header>
                <Grid>
                    <Grid.Column width={4}>Edit Products</Grid.Column>
                    <Grid.Column width={4} style={{ marginRight: "1em" }} textAlign="right" floated="right">
                        {selectedItems.length} selected
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                <Form loading={isModifyingSubscription}>
                    <SubscriptionsTable
                        selectAllCheckbox={
                            <SelectAllCheckbox
                                count={itemsThatCanBeRemoved.length}
                                data={itemsThatCanBeRemoved}
                                idProperty={"subscription_id"}
                                type={"Subscriptions"}
                            />
                        }
                        RowCheckboxComponent={RowCheckboxComponent}
                    />
                    <FullScreenModalPopUp 
                        isOpen={isConfirming}
                        onConfirm={onModifySubscriptions}
                        header="Are you sure?"
                        subheader={"Changes will be made to subscription products"}
                        onFormClose={() => {setIsConfirming(false)}}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={isModifyingSubscription} disabled={isNotAllowedToRemove || isModifyingSubscription} onClick={() => {setIsConfirming(true)}} color='primary'>Remove and Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditSubscriptions;
